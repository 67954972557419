<template>
    <div class="documents" id="account">
        <div class="form-container">
            <h1>Все фонды</h1>
            <div v-if="fundsList" class="form-container__form">
                <rwm-fund-card
                        v-for="fund in fundsList"
                        :key="`${fund.id}-fund`"
                        class="funds-plate"
                        :description="fund.anons"
                        :detales="fund.can_view_details"
                        :title="fund.name"
                        :short_title="fund.short_name ? fund.short_name : fund.name"
                        :to-buy="(fund.status_id === 6 || fund.status_id === 3 || fund.status_id === 7) ? fund.id : undefined"
                        :fund-logo="fund.file_anons"
                        :to-details="fund.id"
                        :fund-ki="fund.kvalInvestor"
                        :is-iam-k-i="isIamKI"
                        @onPush="$emit('onPush', {to: $event, fundKI: fund.kvalInvestor, title: fund.name, isDetales: fund.can_view_details})"
                        @buy="$emit('buy', {...$event, fundStatus: fund.status_id, fundAnkets: fund.ankets, fundKI: fund.kvalInvestor, hasNecessaryDocs: fund.fatca_status === 3 && fund.pod_ft_status === 3
                        , fatca_status: fund.fatca_status
                        , podft_status: fund.pod_ft_status
                        , kiStatus: fund.appl_ci_status
                        })"
                />
            </div>
<!--            <div class="form-container__info">-->
<!--                <p class="anketa">-->
<!--                    Это какой-то информационный текст для всех страниц с карточками. Лица могут быть признаны квалифицированными инвесторами, если они отвечают требованиям, установленным Федеральным законом "О рынке ценных бумаг"и принятыми в соответствии с ним нормативными актами Банка России.-->
<!--                </p>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
    import RwmFundCard from "../../../RWMFundCard/RwmFundCard";
    export default {
        name: "FundsPage",
        components: {RwmFundCard},
        props: {
            fundsList: {
                type: Array,
                default: null
            },
          isIamKI: {
              type: Boolean,
              default: false
          }
        },
    }
</script>

<style scoped lang="scss">

</style>

<template>
  <button
      v-bind="$attrs"
      type="button"
      :class="['RWM-btn', {'RWM-btn--has-bg' : !outlined}, {'default-color' : !color}, {'error' : error}]"
      @mousedown="ripple"
      @mouseup="endRipple()"
      @click.stop="$emit('click')"
      :style="width ? {'width': `${width}`} : ''"
  >
    <slot name="prepend-img" />
    <span class="__content" :style="contentWidth ? {'width': `${contentWidth}`} : ''">
      <slot />
    </span>
    <span v-if="isRipple" class="ripple-container">
      <span class="_ripple animation" :style="{left: `${left}px`, top: `${top}px`, width: `${containerWidth}px`, height: `${containerWidth}px`}" />
    </span><template v-else />
  </button>
</template>

<script>
export default {
  name: "RwmButton",
  props: {
    error: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: undefined
    },
    outlined: {
      type: Boolean,
      default: undefined
    },
    width: {
      type: String,
      default: undefined
    },
    contentWidth: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      isRipple: false,
      left: 0,
      top: 0,
      containerWidth: 0
    }
  },
  methods: {
    ripple(event) {
      this.containerWidth=event.target.offsetWidth;
      this.left=event.offsetX - this.containerWidth/2;
      this.top=event.offsetY - this.containerWidth/2;
      this.isRipple = true;
    },
    endRipple() {
      this.isRipple = false;
    }
  }
}
</script>

<style scoped lang="scss">
button.RWM-btn {
  background: none;
  min-width: 150px;
  height: 48px;
  border: 1px solid $RWM-grey;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  transition: all .2s linear;

  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: "";
    transition: all .2s linear;
  }
  & img {
    margin-right: 24px;
  }
  & .__content {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: $RWM-grey;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  &:hover {
    &:before {
      background: $RWM-grey-super-light;
    }
  }
  &--has-bg {
    background-color: $RWM-grey;
    & .__content {
      color: $RWM-white;
    }
    &:not(:disabled):hover {
      background: none;
      & .__content {
        color: $RWM-grey;
      }
    }
  }
  &:before {
    opacity: 0;
    background: $RWM-white;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  &:hover {
    cursor: pointer;
  }
  &:hover:not(:disabled):before {
    opacity: $RWM-opacity-hover;
  }

  & .ripple-container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    overflow: hidden;
    z-index: -1;
    & ._ripple {
      display: block;
      position: absolute;
      opacity: $RWM-opacity-press;
      background-color: $RWM-grey-super-light;
      border-radius: 100%;
      transform: scale(0);
      animation: ripple 0.25s linear;
      animation-fill-mode: both;
    }
  }
  &:disabled,
  &[disabled] {
    background-color: $RWM-block;
  }
  &.error {
    color: $RWM-red;
    border-color: $RWM-red;
    & .__content {
      color: $RWM-red;
    }
}
}
@keyframes ripple {
  100% {transform: scale(2.5);}
}
</style>

<template>
    <div class="modal-window">
        <div class="modal__close-btn">
            <v-icon @click="$modal.hide('InfoModal')">x</v-icon>
        </div>
        <h1 v-html="title" />
        <div class="modal-window__info">
            <v-icon>{{icon}}</v-icon>
            <p v-html="text" />
        </div>
        <rwm-button width="100%" outlined @click="close">{{buttonText}}</rwm-button>
    </div>
</template>

<script>
    import VIcon from "../icons/VIcon";
    import RwmButton from "../RWMButton/RwmButton";
    export default {
        name: "InfoModal",
        components: {RwmButton, VIcon},
        props: {
            title: String,
            text: String,
            icon: String,
            buttonText: {
                type: String,
                default: "Продолжить"
            },
            proceed: [Object, Boolean]
        },
        methods: {
            close() {
                if (this.proceed) {
                    if(typeof this.proceed.location != "undefined")
                        this.$router.push(this.proceed.location)
                    else
                        this.$router.push('/cabinet/investment/purchase-of-investment-shares/'+this.proceed.toBuy)
                }
                this.$modal.hide('InfoModal')
            }
        }
    }
</script>

<style scoped lang="scss">
    .modal-window {
        height: calc(100vh - 66px);
        @media screen and (min-width: 961px) {
            height: initial;
        }
        h1 {
            padding-top: 60px;
            @media screen and (min-width: 961px) {
                padding-top: initial;
              max-width: 80%;
            }
        }
        &__info {
            display: flex;
            align-items: flex-start;
            column-gap: 27px;
            color: $RWM-red;
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            margin: 44px 0;
        }
    }

</style>
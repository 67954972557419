<template>
    <div class="rwm-fund-card">
        <div class="rwm-fund-card_title">
            <div v-if="fundKi" style="height: 160px; position: relative; width: 0px;">
                <svg style="margin: 5px 0px;" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5179 0.90763C16.5971 0.301298 15.4038 0.301298 14.4831 0.90763L14.0203 1.21236C13.5236 1.53943 12.9346 1.69726 12.3409 1.66235L11.7878 1.62982C10.6873 1.5651 9.65382 2.16175 9.15959 3.14723L8.91121 3.6425C8.64461 4.17409 8.21339 4.6053 7.68181 4.8719L7.18653 5.12029C6.20106 5.61451 5.6044 6.64795 5.66913 7.74851L5.70166 8.30163C5.73657 8.89529 5.57873 9.48434 5.25167 9.98101L4.94694 10.4438C4.3406 11.3645 4.3406 12.5578 4.94694 13.4786L5.25167 13.9413C5.57873 14.438 5.73657 15.027 5.70166 15.6207L5.66913 16.1738C5.6044 17.2744 6.20106 18.3078 7.18653 18.802L7.68181 19.0504C8.21339 19.317 8.64461 19.7482 8.91121 20.2798L9.15959 20.7751C9.65382 21.7606 10.6873 22.3572 11.7878 22.2925L12.3409 22.26C12.9346 22.2251 13.5236 22.3829 14.0203 22.71L14.4831 23.0147C15.4038 23.621 16.5971 23.621 17.5179 23.0147L17.9806 22.71C18.4773 22.3829 19.0664 22.2251 19.66 22.26L20.2131 22.2925C21.3137 22.3572 22.3471 21.7606 22.8414 20.7751L23.0897 20.2798C23.3563 19.7482 23.7876 19.317 24.3191 19.0504L24.8144 18.802C25.7999 18.3078 26.3965 17.2744 26.3318 16.1738L26.2993 15.6207C26.2644 15.027 26.4222 14.438 26.7493 13.9413L27.054 13.4786C27.6603 12.5578 27.6603 11.3645 27.054 10.4438L26.7493 9.98101C26.4222 9.48434 26.2644 8.89529 26.2993 8.30163L26.3318 7.74851C26.3965 6.64795 25.7999 5.61451 24.8144 5.12029L24.3191 4.8719C23.7876 4.6053 23.3563 4.17409 23.0897 3.6425L22.8414 3.14723C22.3471 2.16175 21.3137 1.5651 20.2131 1.62982L19.66 1.66235C19.0664 1.69726 18.4773 1.53943 17.9806 1.21236L17.5179 0.90763Z" fill="#DA291C"/>
                    <path d="M11.8672 31.5472V23.7904L11.8759 23.7899L12.429 23.7574C12.6999 23.7415 12.9687 23.8135 13.1953 23.9627L13.6581 24.2675C15.0794 25.2034 16.9215 25.2034 18.3429 24.2675L18.8056 23.9627C19.0322 23.8135 19.301 23.7415 19.5719 23.7574L20.1251 23.7899L20.1482 23.7912V31.5472L16.0077 27.8602L11.8672 31.5472Z" fill="#DA291C"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0047 4.12555C11.6772 4.12555 8.16901 7.63371 8.16901 11.9612C8.16901 16.2888 11.6772 19.7969 16.0047 19.7969C20.3322 19.7969 23.8404 16.2888 23.8404 11.9612C23.8404 7.63371 20.3322 4.12555 16.0047 4.12555ZM7.03906 11.9612C7.03906 7.00966 11.0531 2.99561 16.0047 2.99561C20.9563 2.99561 24.9703 7.00966 24.9703 11.9612C24.9703 16.9128 20.9563 20.9269 16.0047 20.9269C11.0531 20.9269 7.03906 16.9128 7.03906 11.9612Z" fill="white"/>
                    <path d="M15.617 8.57215C15.7473 8.23597 16.223 8.23597 16.3533 8.57215L17.0416 10.348C17.0976 10.4926 17.233 10.5909 17.3878 10.5996L19.2895 10.7054C19.6494 10.7254 19.7964 11.1778 19.517 11.4056L18.0407 12.609C17.9206 12.7069 17.8688 12.8661 17.9085 13.016L18.3955 14.8573C18.4877 15.2058 18.1028 15.4854 17.7998 15.29L16.1991 14.2579C16.0689 14.1739 15.9015 14.1739 15.7712 14.2579L14.1705 15.29C13.8675 15.4854 13.4827 15.2058 13.5749 14.8573L14.0619 13.016C14.1015 12.8661 14.0498 12.7069 13.9297 12.609L12.4534 11.4056C12.1739 11.1778 12.3209 10.7254 12.6809 10.7054L14.5826 10.5996C14.7373 10.5909 14.8727 10.4926 14.9288 10.348L15.617 8.57215Z" fill="white"/>
                </svg>
            </div>
            <div class="rwm-fund-card_title_img">
                <img :src="fundLogo" />
            </div>
            <div class="rwm-fund-card_title_text fund_name"
                 v-html="title"
                 @click="detales ? $emit('onPush', `/cabinet/funds/fund/${toDetails}`) : ''"
            />
            <div class="rwm-fund-card_title_text fund_short_name"
                 v-html="short_title"
                 @click="detales ? $emit('onPush', `/cabinet/funds/fund/${toDetails}`) : ''"
            />
        </div>
        <div class="rwm-fund-card_body">
            <div class="rwm-fund-card_body_text" v-html="description" />
            <div class="rwm-fund-card_body_actions">
                <div class="rwm-fund-card_body_actions_action">
                    <rwm-button :disabled="!detales && !fundKi" outlined width="100%" @click="$emit('onPush', `/cabinet/funds/fund/${toDetails}`)">Подробнее</rwm-button>
                </div>
<!--                :class="{'invisible' : !toBuy}"-->
                <div class="rwm-fund-card_body_actions_action">
                    <rwm-button width="100%" @click="$emit('buy', {toBuy, title})">Купить</rwm-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RwmButton from "../RWMButton/RwmButton";
    export default {
        name: "RwmFundCard",
        components: {RwmButton},
        props: {
            title: {
                type: String,
                default: "",
            },
            short_title: {
                type: String,
                default: "",
            },
            fundLogo: {
                type: String,
                default: null
            },
            description: String,
            toDetails: {
                type: [String, Number],
                default: null
            },
            toBuy: {
                type: [String, Number],
                default: null
            },
          detales: {
              type: Boolean,
              default: true,
          },
          isIamKI: {
            type: Boolean,
            default: false,
          },
          fundKi: {
            type: Boolean,
            default: false,
          }
        },
    }
</script>

<style scoped lang="scss">
    .rwm-fund-card {
        box-sizing: border-box;
        border: 1px solid $RWM-grey-light;
        border-radius: 5px;
        cursor: default;
        width: 100%;
        @media screen and (min-width: 1280px) {
            width: calc(50% - 16px);
            display: flex;
            flex-direction: column;
        }
        &_title {
            height: 160px;
            max-height: 160px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid $RWM-grey-light;
            overflow: hidden;
            &_img {
                display: none;
                @media screen and (min-width: 600px) {
                    display: block;
                    flex: 0 1 40%;
                    overflow: hidden;
                    margin-left: 32px;
                    max-height: inherit;
                    align-self: flex-end;
                    text-align: center;
                }
                & img {
                    object-fit: contain;
                    max-width: 100%;
                    max-height: inherit;
                }
            }
            &_text {
                flex: 1 1 60%;
                font-weight: bold;
                font-size: 25px;
                line-height: 34px;
                color: $RWM-red;
                padding: 10px 32px;
                cursor: pointer;
            }
        }
        &_body {
            margin: 32px;
            display: flex;
            flex-direction: column;
            row-gap: 32px;
            flex: 1 1 auto;
            &_text {
                font-size: 18px;
                line-height: 25px;
                color: $RWM-grey;
                flex: 1 1 auto;
            }
            &_actions {
                display: flex;
                flex-direction: column;
                row-gap: 18px;
                @media screen and (min-width: 600px) {
                    flex-direction: row;
                    column-gap: 40px;
                }
                &_action {
                    flex: 1;
                    @media screen and (min-width: 600px) {
                        max-width: 50%;
                    }
                    /*& .invisible {*/
                    /*    opacity: 0;*/
                    /*    cursor: default !important;*/
                    /*}*/
                }
            }
        }
    }
</style>
